
@import url('../src/assets/css/bootstrap.css');
@import url('../src/assets/css/font-awesome.css');
@import url('./assets/css/style.css');
@import url('./assets/css/fastselect.min.css');

/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
.modal-content {	
	max-width: 470px;
	margin: 50px auto auto auto;
}