* {
	margin: 0;
	padding: 0;
}

aside,
section,
article,
p,
div,
span,
figure,
figcaption,
img,
input,
form,
html,
footer,
header,
nav,
p,
ul,
li {
	margin: 0;
	padding: 0;
}

ul {
	list-style: none;
}

.clear {
	clear: both;
	height: 0;
}

:focus,
.btn:focus,
.btn:active:focus {
	outline: none;
}

ul:after {
	clear: both;
	display: block;
	content: "";
}

html {
	font-smooth: always;
}

img {
	max-width: 100%;
	border: none;
	vertical-align: bottom;
	user-select: none;
	-moz-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
}

button,
input[type="submit"] {
	-webkit-appearance: none;
}

body {
	background: #eeeef3;
	color: #242424;
	font-size: 16px;
	line-height: 21px;
	font-family: 'Lato', sans-serif;
	font-weight: 400;
	overflow-x: hidden;
}

body,
html {
	height: 100%;
}

a {
	color: #2e4485;
}

h1,
h2,
h3,
h4,
h5 {
	font-weight: 400;
}

.logo_header {

	padding: 70px 0 30px;
}

.login_form_outer {
	max-width: 430px;
	margin: 0px auto 0;
}

.login_form {
	background: #fff;
	border-radius: 5px;
	padding: 30px;
}

.shadow {
	vertical-align: top;
}

.login_form h2 {
	border-bottom: 1px solid #e5e5e5;
	color: #d66e52;
	font-weight: 400;
	margin: 0;
	padding-bottom: 20px;
}

.login_form label {
	font-size: 18px;
	color: #b5bbcb;
	font-weight: 400;
}

.login_form .form-control {
	box-shadow: none;
	height: auto;
	padding: 8px 15px;
}

.forgot_password a {
	color: #d66e52;
	text-decoration: underline;
	font-size: 14px;
}

.forgot_password a:hover {
	text-decoration: none;
}

.login_form .form-group {
	margin-top: 20px;
	margin-bottom: 0;
}

.blue_btn {
	background: #d66e52;
	border-radius: 5px;
	color: #fff;
	font-size: 18px;
	border: none;
	padding: 7px 30px;
	display: inline-block;
	line-height: 26px;
	margin-top: 20px;
}

.login_body {
	background: #12abb0;
	min-height: 100vh;
}

div#root {
	min-height: 100%;
}

.blue_btn:hover {
	background: #d66e52;
}

.login_form h2+p {
	margin-top: 20px;
}


.logo_header.text-center img {
	width: 85px;
}

.white_three img {
	padding: 10px;
}

/*** dashboard page css here ***/
.header {
	background: #fff;
	box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
	height: 78px;
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	-webkit-transform: translateZ(0);
	z-index: 10;
	min-width: 700px;
}

.dashboard_logoarea {
	background: white;
	float: left;
	width: 300px;
	height: 78px;
	padding: 13px 0;
}

.dashboard_logoarea img {
	height: 50px;
}

.right_loggedarea {
	float: right;
	margin-right: 15px;
	margin-top: 18px;
}

.right_loggedarea ul li {
	display: inline-block;
	margin-left: 25px;
	font-size: 14px;
	vertical-align: middle;
}

.right_loggedarea ul li a {
	color: #b5bbcb;
}

.right_loggedarea ul li a i,
.right_loggedarea ul li a:hover {
	color: #999999;
}

.notification_count {
	display: inline-block;
	min-width: 22px;
	min-height: 22px;
	background: #d66e52;
	text-align: center;
	color: #fff;
	font-size: 12px;
	border-radius: 100%;
	margin-left: 5px;
}

.right_loggedarea ul li img {
	vertical-align: middle;
	margin-right: 10px;
	height: 40px;
	width: 40px;
	border-radius: 100%;
}

.right_loggedarea ul li a i {
	margin-left: 10px;
}

.main-page {
	padding-top: 78px;
	padding-left: 300px;
	height: 100%;
	min-width: 700px;
}

.left_sidebar {
	position: fixed;
	left: 0;
	top: 0;
	height: 100%;
	background: #12abb0;
	width: 300px;
	padding-top: 85px;
	z-index: 1;
	overflow: auto;
	-webkit-transform: translateZ(0);
	transition: all .3s ease-in;
	-moz-transition: .3s all ease-in;
	-webkit-transition: all .3s ease-in;
	-ms-transition: .3s all ease-in;
	-o-transition: .3s all ease-in;
}

.left_sidebar ul li {
	border-bottom: 1px solid #57699d;
}

.left_sidebar ul li:last-child {
	border-bottom: none;
}

.left_sidebar ul li a {
	color: #fff;
	display: block;
	padding: 12px;
}

.left_sidebar ul li a i {
	min-width: 30px;
}

.left_sidebar ul li.active a,
.left_sidebar ul li:hover a {
	color: #2c2c2c;
}

.left_sidebar ul li.active a span,
.left_sidebar ul li:hover a span {
	text-decoration: underline;
}

.content_area {
	padding: 15px 15px 35px 15px;
	min-height: 100%;
	position: relative;
}

.top3_area {
	padding: 10px 0 45px;
}

.white_three {
	background: #fff;
	margin: 20px 0 0 29px;
	text-align: center;
	padding: 25px;
	position: relative;
	border-radius: 2px;
	box-shadow: 0px 8px 28px rgba(0, 0, 0, .12);
}

.white_three p {
	color: #b5bbcb;
}

.white_three span {
	position: absolute;
	left: -29px;
	width: 58px;
	height: 58px;
	font-size: 30px;
	color: #fff;
	line-height: 55px;
	border-radius: 2px;
	top: 15px;
}

.white_three h3 {
	margin-top: 0;
}

.white_box {
	background: #fff;
	padding: 15px;
	border-radius: 2px;
}

.total_sold h4 {
	font-weight: 700;
	color: #333333;
	margin-bottom: 35px;
}

.footer {
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	padding: 10px 15px;
}

.right_loggedarea .dropdown-menu {
	background: #12abb0;
	right: 0;
	left: inherit;
	top: 30px;
	min-width: 200px;
}

.right_loggedarea .dropdown-menu a {
	display: block;
	color: #fff;
	font-size: 16px;
	padding: 10px;
	border-bottom: 1px solid #6b8498;
	cursor: pointer;
}

.right_loggedarea .dropdown-menu a:last-child {
	border-bottom: none;
}

.right_loggedarea .dropdown-menu a i {
	color: #fff;
	vertical-align: middle;
	min-width: 25px;
}

.right_loggedarea .dropdown-menu a:hover,
.right_loggedarea .dropdown-menu a:hover i {
	color: #fff;
}

.right_loggedarea .dropdown-menu::after {
	position: absolute;
	content: "";
	border-top: 10px solid transparent;
	border-bottom: 10px solid #12abb0;
	border-left: 10px solid transparent;
	border-right: 10px solid transparent;
	top: -20px;
	right: 5px;
}

.yellow_btn {
	font-size: 16px;
	font-weight: 700;
	padding: 6px 20px;
}

.tables_area .white_box {
	padding: 0;
	box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
	margin-top: 15px;
}

.table-responsive table thead tr th {
	color: #2e4485;
	font-size: 14px;
	font-weight: 700;
	border-bottom: 2px solid #2e4485;
	text-transform: uppercase;
	padding: 10px;
	text-align: center;
}

.table-responsive table tbody tr td {
	color: #242424;
	font-size: 14px;
	border-bottom: 1px solid #ccc;
	border-right: 1px solid #ccc;
	padding: 12px 10px;
	text-align: center;
	max-width: 200px;
	word-break: break-all;
}

.table-responsive table tbody tr td:last-child {
	border-right: none;
}

.table-responsive table tbody tr:nth-child(2n) td {
	background: #eeeef3;
}

.action_btn {
	color: #242424;
	font-size: 18px;
	display: inline-block;
	margin: 0 5px;
}

.action_btn:hover {
	color: #d66e52;
}

/*** dashboard page css here ***/

/*** add new fleet page css here ***/
.add_formarea {
	padding: 15px;
}

.big_yellow {
	font-size: 18px;
	padding: 10px 35px;
	margin-bottom: 20px;
}

.add_formarea label {
	font-size: 14px;
	color: #242424;
	font-weight: 400;
	letter-spacing: 1px;
}

.add_formarea .form-control {
	font-size: 16px;
	color: #242424;
	height: auto;
	box-shadow: none;
	padding: 8px 12px;
}

.add_map a {
	font-size: 14px;
	text-decoration: underline;
}

.icon_input {
	position: relative;
}

.icon_input i {
	font-size: 20px;
	position: absolute;
	right: 10px;
	top: 10px;
}

.form-group h3 {
	margin-top: 5px;
}

.custom_select select {
	width: 100%;
	font-size: 16px;
	border: 1px solid #ccc;
	border-radius: 5px;
	padding: 8px;
	line-height: 40px;
}

.custom_select {
	position: relative;
}

.custom_select::after {
	position: absolute;
	top: 1px;
	width: 40px;
	background: #fff;
	bottom: 1px;
	text-align: center;
	content: "\f0dd";
	font-family: fontawesome;
	right: 1px;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
	font-size: 20px;
	line-height: 30px;
	pointer-events: none;
}

.tags li {
	display: inline-block;
	margin-top: 12px;
}

.tags li span {
	display: inline-block;
	border-radius: 5px;
	background: #2e4485;
	font-size: 14px;
	position: relative;
	padding-right: 20px;
	color: #fff;
	padding: 3px 25px 3px 8px;
}

.tags li span a {
	position: absolute;
	right: 7px;
	top: 3px;
	color: #fff;
}

.top_form {
	padding-bottom: 20px;
}

.top_form .yellow_btn {
	height: 39px;
	margin: 0;
	vertical-align: middle;
	padding: 6px 35px;
	font-size: 18px;
}

/*** add new fleet page css here ***/

/*** accepted payment page css here ***/
.payment_whitebox {
	background: #fff;
	padding: 12px 25px;
	border-radius: 3px;
	border: 2px solid #fff;
	box-shadow: 0px 8px 28px rgba(0, 0, 0, .12);
	margin-bottom: 15px;
}

.payment_options li {
	float: left;
	width: 210px;
	text-align: center;
	text-transform: uppercase;
	margin-top: 20px;
	margin-right: 20px;
	font-size: 14px;
}

.payment_options li:last-child {
	margin-right: 0;
}

.payment_options li a {
	color: #242424;
}

.payment_options li a.active {
	color: #2e4485;
}

.payment_options li a.active .payment_whitebox {
	border-color: #2e4485;
}

.payment_options {
	padding-bottom: 20px;
}

/*** accepted payment page css here ***/

/*** my Profile page css here ***/
.profile_image {
	border: 2px solid #c2c2c2;
	margin: 20px 0;
	width: 200px;
	height: 200px;
}

.user_image {
	border: 2px solid #c2c2c2;
	width: 50px;
	height: 50px;
}

.my_profile label {
	font-size: 14px;
	color: #b5bbcb;
	text-transform: uppercase;
	letter-spacing: 1px;
	font-weight: 400;
}

.my_profile h4 {
	font-size: 18px;
	margin-top: 0px;
}

.my_profile h4 a {
	text-decoration: underline;
}

.my_profile .col-sm-8 .row {
	margin-top: 15px;
}

.upload_text {
	color: #c4c4c5;
	font-size: 14px;
}

.upload_image {
	position: relative;
	display: inline-block;
	overflow: hidden;
}

.upload_image input {
	position: absolute;
	top: 0;
	height: 100%;
	width: 100%;
	opacity: 0;
}

.my_profile .form-control {
	height: auto;
	padding: 10px;
	color: #000;
	box-shadow: none;
	margin-bottom: 20px;
}

.my_profile .yellow_btn {
	font-size: 18px;
	padding: 10px 35px;
	margin: 0px 0;
}

.user_name {
	padding-top: 55px;
}

.padding_top_80 {
	padding-top: 80px;
}

.top_form .form-control,
.search_input .form-group .form-control {
	height: auto;
	padding: 8px 10px;
	box-shadow: none;
}

/*** my Profile page css here ***/

/*** payment transaction page css here ***/
.top_form h4 {
	margin-top: 30px;
}

.search_form li {
	float: left;
	width: 19%;
	margin-right: 1.2%;
}

.search_form li:last-child {
	margin-right: 0;
}

/*** payment transaction page css here ***/

/*** subscription page css here ***/
.subscription_box {
	background: #fff;
	padding: 12px;
}

.subscription_box h1 {
	font-weight: 700;
	color: #2e4485;
	font-size: 62px;
}

.subscription_time {
	position: relative;
	color: #d66e52;
	text-transform: uppercase;
}

.subscription_time:after {
	position: absolute;
	left: 0;
	top: 20px;
	content: "";
	width: 100%;
	height: 1px;
	background: #d66e52;
}

.subscription_time span {
	display: inline-block;
	background: #fff;
	padding: 10px;
	position: relative;
	z-index: 1;
}

.subscription_box p {
	color: #b5bbcb;
	font-size: 18px;
	margin-top: 25px;
}

.subscription_box h3 {
	margin-top: 10px;
}

/*** subscription page css here ***/

/*** booking page css here ***/
.search_input .form-group {
	margin: 15px 0 0 0;
}

.search_input {
	min-width: 260px;
}

.ticket_detailbox p,
.ticket_detailbox h5 {
	color: #b5bbcb;
	font-size: 16px;
}

.ticket_detailbox p span {
	color: #000;
}

.ticket_detailbox .yellow_btn {
	font-size: 18px;
}

.ticket_detailbox h5 span {
	margin-left: 15px;
}

.ticket_detailbox h5 {
	margin-top: 30px;
}

.onward_returntable table thead tr th {
	border-color: #b5bbcb;
}

.onward_returntable table tbody tr td {
	vertical-align: top;
	border-right: none;
}

.onward_returntable table tbody tr:last-child td {
	border-bottom: none;
}

.onward_returntable table tbody tr td h4 {
	font-weight: 700;
	margin: 0;
	color: #242424;
}

.ticket_detailbox td {
	background: #fff !important;
}

.ticket_detailbox>td {
	padding: 0px 25px 25px !important;
}

.hide_detail {
	display: inline-block;
	margin: 0 10px;
}

.dashboard_logoarea a {
	display: inline-block;
	vertical-align: middle;
}

.dashboard_logoarea a.menu_bar {
	display: none;
	vertical-align: middle;
	font-size: 28px;
	margin-right: 15px;
}

/*** booking page css here ***/

/*** date picker css here ***/
.ui-datepicker.ui-widget {
	background: #fff;
	border: 1px solid #ddd;
	min-width: 311px;
}

.ui-datepicker.ui-widget .ui-datepicker-header {
	background: none;
	border: none;
}

.ui-datepicker.ui-widget .ui-datepicker-prev .ui-icon,
.ui-datepicker.ui-widget .ui-datepicker-next .ui-icon {
	background: url("../images/left_arrow.png") no-repeat scroll center center;
	cursor: pointer;
	height: 35px;
	left: 0;
	margin: 0;
	top: 0;
	width: 35px;
}

.ui-datepicker.ui-widget .ui-datepicker-next .ui-icon {
	background: url("../images/right_arrow.png") no-repeat scroll center center;
}

.ui-datepicker-title {
	color: #000;
}

.ui-datepicker.ui-widget a.ui-state-active {
	background: #15a5fb;
	color: #fff;
}

.ui-datepicker.ui-widget .ui-state-default,
.ui-datepicker.ui-widget .ui-widget-content .ui-state-default,
.ui-datepicker.ui-widget .ui-widget-header .ui-state-default {
	color: #000;
	padding: 0 10px;
	text-align: center;
	text-shadow: none;
}

.ui-datepicker .ui-datepicker-prev {
	left: 2px !important;
	top: 2px !important;
}

.ui-datepicker .ui-datepicker-next {
	right: 2px !important;
	top: 2px !important;
}

.bootstrap-timepicker-widget.dropdown-menu.open {
	display: block;
	padding: 5px;
}

.bootstrap-timepicker-widget {
	text-align: center;
}

.bootstrap-timepicker-widget input {
	border: 1px solid #ccc;
	padding: 5px;
	text-align: center;
	border-radius: 5px;
	width: 75px;
}

.bootstrap-timepicker-widget table td {
	padding: 5px 10px;
}

/*** date picker css here ***/

.custom_select .fstElement {
	display: block;
	font-size: 12px;
	border-radius: 5px;
}

.custom_select .fstElement .fstControls {
	width: 100%;
}

.custom_select .fstElement .fstControls .fstQueryInput {
	color: #000;
	padding: 5px;
}

.custom_select .fstChoiceItem,
.custom_select .fstResultItem.fstSelected,
.custom_select .fstResultItem.fstFocused {
	background-color: #2e4485;
	border-color: #2e4485;
}

.attireMainNav {
	display: none;
}

.add_formarea h4 {
	text-transform: uppercase;
	font-weight: 600;
	margin-bottom: 15px;
	color: #2e4485;
}



.online_user h4 {
	font-weight: 700;
	color: #333333;
	margin-bottom: 35px;
}

.online_user h4 a {
	color: #d66e52;
	font-size: 16px;
	font-weight: 400;
	text-decoration: underline;
	float: right;
}

.online_user h4 a:hover {
	text-decoration: none;
}

.online_user ul li {
	position: relative;
	padding-left: 80px;
	border-bottom: 1px solid #dddddd;
	min-height: 98px;
	padding-right: 15px;
	padding-top: 15px;
	padding-bottom: 15px;
}

.online_user ul li:last-child {
	border-bottom: none;
}

.online_user ul li img {
	border-radius: 100%;
	border: 2px solid #cdcdcd;
	position: absolute;
	left: 0;
	top: 15px;
	width: 68px;
	height: 68px;
}

.online_user_status {
	width: 10px;
	height: 10px;
	border-radius: 100%;
	background: #00ff0c;
	position: absolute;
	display: inline-block;
	right: 0;
	top: 30px;
}

.online_user ul li p {
	padding-top: 23px;
}

.current_commission {
	background: #d7e7db;
	font-size: 18px;
	color: #242424;
	border-radius: 5px;
	padding: 20px;
	margin-bottom: 45px;
}

.plan_action_btn {
	color: #242424;
	display: inline-block;
	margin-top: 10px;
	margin-right: 20px;
}

.plan_action_btn:hover {
	color: #2e4485;
}

.padding_20 {
	padding: 20px;
}

canvas.chartjs-render-monitor {
	height: 400px !important;
}

.view_jobs {
	cursor: pointer;
	font-size: 13px;
	text-decoration: underline;
}


/* css-rjt */
li.active.active_list span {
	background: #fff;
	color: #16aab0;
	border-radius: 50%;
	padding: 5px;
}

li.active_list span {
	background: #16aab0;
	color: #fff;
	border-radius: 50%;
	padding: 5px;
}

li.upcoming_list span,
li.past-list span {
	background: #16aab0;
	color: #fff;
	border-radius: 50%;
	padding: 5px;
}

li.active.upcoming_list span,
li.active.past-list span {
	background: #fff;
	color: #16aab0;
	border-radius: 50%;
	padding: 5px;
}

/* css-rjt */

/* 11-aug-css */
.replied-popup .swal2-title {
	color: #000;
	font-size: 25px;
}

.replied-popup #swal2-html-container {
	font-size: 16px;
	line-height: 24px;
}

.replied-popup button.swal2-confirm.swal2-styled {
	font-size: 16px;
	background-color: #12abb0;
}

.input-group.search_right.pull-right.category-search-top-right {
	display: flex;
}

.input-group.search_right.pull-right.category-search-top-right button {
	min-width: 150px;
	background: #12abb0;
	font-weight: 500;
	margin-left: 5px;
	width: fit-content;
}

.input-group.search_right.pull-right.category-search-top-right select.form-select {
	width: fit-content;
	min-width: 150px;
	padding: 10px;
	border: 1px solid #00000036;
	border-radius: 6px;
	background: #ffffffbd;
	margin-right: 5px;
}

.upload_image.catory-images img.img-circle.profile_image {
	width: 120px;
	height: 120px;
	border: 2px solid #c2c2c25c;
	min-width: 120px;
	min-height: 120px;
	margin-top: 0;
}

.upload_image.catory-images h5 {
	font-weight: 700;
	font-size: 17px;
	text-align: center;
}

.upload-area {
	display: flex;
	justify-content: center;
}

.category-modal .modal-title {
	display: flex;
	justify-content: space-between;
	font-weight: 700;
}

.upload_image.catory-images {
	margin: 0 12px 0 0;
}

.page_header.privacy-policy {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 10px;
}

.page_header.privacy-policy select {
	width: fit-content;
	min-width: 150px;
	padding: 10px;
	border: 1px solid #00000036;
	border-radius: 6px;
	background: #ffffffbd;
}

.input-group.search_right.pull-right.privacy-policy button {
	min-width: 150px;
	background: #12abb0;
	font-weight: 500;
	margin-left: 5px;
	width: -moz-fit-content;
	width: fit-content;
	padding: 10px 15px;
}

.ck-blurred.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline {
	height: 100%;
	min-height: 300px;
}

button.save-bt.sumbit-btn-bottom {
	background: #d66e52;
	border: none;
	color: #fff;
	padding: 10px 35px;
	margin: 30px 0 0;
	border-radius: 5px;
	font-weight: 700;
}

.home-content h3.subtitle {
	font-size: 18px;
	margin: 5px 0;
	font-weight: 600;
}

textarea.form-control {
	border: 1px solid #00000026;
	border-radius: 10px;
}

.row.contact-info {
	justify-content: center;
	display: flex;
}

.contact-info .col-md-8.col-lg-6.col-xl-5 {
	background: #fff;
	padding: 30px;
	border-radius: 20px;
	border: 1px solid #00000024;
}

.contact-info h2.subtitle {
	margin: 0 0 20px 0;
}

.contact-info h4.subtitle {
	font-size: 16px;
	font-weight: 600;
	margin: 6px 0;
}

input.form-control {
	padding: 22px 12px;
	border: 1px solid #00000021;
}

.how-it-wrk-select select {
	margin: 0 0 0 8px;
}

.how-it-wrk-area {
	background: #fff;
	padding: 30px;
	border-radius: 10px;
	border: 1px solid #00000024;
}

.how-it-wrk-area h2.subtitle {
	margin-top: 0;
}

.how-it-wrk-area h4.subtitle {
	font-weight: 600;
	margin: 6px 0;
}

.how-it-wrk-area .App select.form-select {
	width: 100%;
	padding: 11px;
	border: 1px solid #dedede;
	border-radius: 5px;
}

.content-subcategory ul li a {
	padding: 5px 0 3px 0;
	font-size: 15px;
}

.content-subcategory ul {
	padding-left: 0 !important;
}

.content-subcategory ul li {
	list-style: disc;
	color: #fff;
	border-bottom: 0;
}

.content-subcategory {
	padding: 0 0 15px 60px;
}

select.form-select.home-image-right-area {
	width: 140px;
	padding: 5px;
	margin-right: 4px;
}

img.img-circle.profile_image.upload-picture {
	width: 130px;
	height: 130px;
	min-width: 130px;
	min-height: 130px;
	border-radius: 50%;
	object-fit: cover;
}

.form-group.qty-area select.form-select {
	width: 100%;
	padding: 11px;
	border: 1px solid #dedede;
	border-radius: 5px;
}

.home-content {
	background: #fff;
	border-radius: 10px;
	border: 1px solid #00000024;
	padding: 20px;
}

.home-content h2.subtitle {
	margin-top: 0;
}

a.privacy-policy-option {
	position: relative;
}

a.privacy-policy-option i.fa.fa-angle-down {
	position: absolute;
	right: 0;
	top: 12px;
	font-weight: bolder;
	color: #fff;
}

select.form-select.home-image-right-area {
	width: 140px;
	padding: 5px;
	margin-right: 4px;
	border: 1px solid #ccc;
	border-radius: 5px;
}

li.language-select-area select {
	padding: 10px 33px 10px 17px;
	background: #1aacb2;
	color: #fff;
	border: none;
	border-radius: 20px;
	margin-left: 20px;
	font-weight: 600;
}

li.language-select-area i {
	position: absolute;
	top: 10px;
	right: 10px;
	color: #ffff;
	font-weight: 800;
	font-size: 15px;
}

li.language-select-area {
	position: relative;
}

li.language-select-area select:focus-visible {
	border: none;
	outline: none;
}

.ck.ck-editor__main,
.ck.ck-content {
	min-height: 300px;
	height: 100%;
}

h5.provider-area {
	margin-top: 0;
}

.home-content h5 {
	font-weight: 600;
}

.block-user-left {
	display: flex;
}

.payout-popup .modal-title {
	display: flex;
	justify-content: space-between;
	align-content: center;
	font-weight: 600;
}

.payout-detail-inner {
	display: flex;
	align-content: center;
	justify-content: space-between;
	border-bottom: 1px solid #00000012;
	padding: 15px 0;
}

.payout-detail-inner h4 {
	font-size: 15px;
	color: #4e4e4e;
	font-weight: 500;
}

.payout-popup .modal-body {
	padding-top: 5px;
}

.payout-detail-inner label,
.payout-detail-inner h4 {
	margin: 0 !important;
}

.filter_date {
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.filter_date div {
	margin: 0 12px;
}

.filter_date button {
	padding: 11px 22px;
	background: #12abb0;
	border-color: #12abb0;
	margin-top: 23px;
	margin-right: 5px;
	color: #fff;
}

.main-outer-div {
	display: flex;
	justify-content: space-between;
	margin-bottom: 13px;
}

.input-group.search_right.pull-right.outer-main-search {
	float: unset !important;
}

.tooltip {
	position: relative;
	display: inline-block;
	font-family: unset !important;
	font-size: unset !important;
	opacity: unset !important;
}

.tooltip .tooltiptext {
	visibility: hidden;
	width: 181px;
	background-color: #555;
	color: #fff;
	text-align: center;
	border-radius: 6px;
	padding: 5px 0;
	position: absolute;
	z-index: 99999999;
	bottom: -20px;
	left: 204%;
	margin-left: -60px;
	transition: opacity 0.3s;
}


.tooltip .tooltiptext::after {
	content: "";
	position: absolute;
	top: 48%;
	left: -6px;
	transform: rotate(91deg);
	margin-left: -5px;
	border-width: 5px;
	border-style: solid;
	border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
	visibility: visible;
	opacity: 1;
}

.white-space-nowrap {
	white-space: nowrap;
}

.inner-main-table .react-bootstrap-table table {
	table-layout: unset !important;
}

@media screen and (max-width:1500px) {
	.slider_preview_post .col-md-1 {
		display: none;
	}

	.col-md-6.slider_right_cont {
		width: 41%;
	}
}

/*** responsive css here ***/
@media screen and (max-width:1200px) {
	.search_form li {
		width: 32%;
		margin-right: 1.333%;
	}

	.online_user,
	.subscription_box {
		margin-top: 15px;
	}
}

@media screen and (max-width:991px) {
	.main-page {
		padding-left: 0;
	}

	.left_sidebar {
		left: -270px;
	}

	.left_sidebar.active {
		left: 0;
	}

	.dashboard_logoarea a.menu_bar {
		display: inline-block;
	}
}

@media screen and (max-width:767px) {
	.login_form_outer {
		margin: 20px auto 0;
	}
}

/*** responsive css here ***/


/*** tab css ***/

.my_job_tab_box {
	padding: 30px 30px 70px 30px;
	background: #f6f8fa;
	position: relative;
}

.tab_heading_content .nav-tabs {
	border-bottom: none;
}

.tab_heading_content .nav-tabs>li>a {
	margin-right: 0px;
	line-height: 1.42857143;
	border: 1px solid #12abb0;
	border-radius: 60px 0px 0px 60px;
	padding: 9px 32px;
	font-size: 18px;
	color: #2c2c2c;
	/* max-width: 145px; */
	max-width: 200px;
}

.tab_heading_content .nav-tabs>li.upcoming_list>a {
	border-right: transparent;
	border-top: 1px solid #12abb0;
	border-bottom: 1px solid #12abb0;
	border-radius: 0px 0px 0px 0px;
	border-left: transparent;
}

.tab_heading_content .nav-tabs>li.past-list>a {
	border-radius: 0px 60px 60px 0px;
}

.tab_heading_content .nav-tabs>li.active>a,
.nav-tabs>li.active>a:focus,
.nav-tabs>li.active>a:hover {
	color: #fff;
	cursor: default;
	background-color: #12abb0;
	border: 1px solid transparent;
	/*border-bottom-color: transparent;*/
}

.tab_heading_content .add_post.button {
	float: right;
}

.tab_heading_content .add_post.button a {
	display: inline-block;
	background: #d66e52;
	font-size: 18px;
	color: #fff;
	padding: 11px 34px;
	border-radius: 60px;
}

.tab_heading_content .add_post.button a:hover {
	background: #db565a;
	text-decoration: none;
}

.my_job_content {
	padding: 20px 0px 0px 0px;
}

.pink_button {
	font-size: 14px;
	font-weight: bold;
	color: #fff;
	background: #d66e52;
	border-radius: 60px;
	text-transform: capitalize;
	text-align: center;
	padding: 8px 15px;
	display: inline-block;
	text-decoration: none;
	cursor: pointer;
	box-shadow: 0px 10px 15px rgba(255, 147, 38, 0.15);
	max-width: 115px;
	width: 100%;
	border: 1px solid #d66e52;
}

.pink_button:hover {
	text-decoration: none;
	background: #cc4b29;
	color: #fff;
}


.pink_border {
	background: #fff;
	color: #d66e52;
	margin-left: 4px;
}

.mark_read_btn {
	color: #fff;
	background: #d66e52;
	box-shadow: 0px 4px 0px rgba(255, 147, 38, 0.15);
	font-size: 13px;
	font-weight: 600;
	text-align: center;
	border-radius: 60px;
	padding: 6px 20px;
	border: none;
	white-space: nowrap;
}

.mark_read_btn_green {
	color: #fff;
	background: #6bbe53;
	box-shadow: 0px 4px 0px rgba(255, 147, 38, 0.15);
	font-size: 13px;
	font-weight: 600;
	text-align: center;
	border-radius: 60px;
	padding: 6px 20px;
	border: none;
	white-space: nowrap;
}

.suspend_button {
	background: #d66e52;
	border: none;
	padding: 5px 9px;
	border-radius: 3px;
	color: #fff;
	white-space: nowrap;
}

.active_button {
	background: #6bbe53;
	border: none;
	padding: 5px 9px;
	border-radius: 3px;
	color: #fff;
}



.preview_post_banner_area {
	background: url(../images/preview_background_image.png);
	height: 250px;
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;
}

.preview_post_banner_area::before {
	content: "";
	position: absolute;
	background: #000;
	width: 100%;
	height: 100%;
	top: 0px;
	left: 0px;
	opacity: 0.8;
}

.slider-box .carousel-inner {
	border-radius: 10px;
}

.slider-box .left.carousel-control,
.right.carousel-control {
	display: none;
}

.slider-box .carousel-indicators li {
	background-color: #898681;
	border: 1px solid #898681;
}

.slider-box .carousel-indicators {
	position: absolute;
	bottom: 14px;
	left: 20px;
	z-index: 15;
	width: auto;
	padding-left: 0;
	margin-left: 0px;
	margin-bottom: 0px;
}

.slider-box .carousel-indicators .active {
	background-color: #fff;
	border: 1px solid #fff;
}

.pulish_button-box {
	padding: 10px 30px 0px 30px;
}

.pulish_button-box .main_btn {
	margin-top: 20px;
}

.border_button a {
	background: transparent;
	color: #79a1f6;
}

.slider_right_content h1 {
	font-size: 28px;
	font-weight: 600;
	line-height: normal;
}

.slider_right_content ul li p {
	font-size: 18px;
	color: #8e8d8d;
}

.slider_right_content ul li p span {
	float: right;
	color: #2c2c2c;
}

.slider_right_content ul li {
	padding: 15px 0px;
	border-bottom: 1px solid #f0f2f4;
}

.slider_right_content ul li.location {
	padding-bottom: 38px;
}

.slider_right_content ul li.location p span {
	text-align: right;
}

.slider_right_content ul li:last-child {
	padding: 15px 0px 0px 0px;
	border-bottom: none;
}

.slider_right_content ul li p.description_content {
	color: #2c2c2c;
	margin: 10px 0px 0px 0px;
	line-height: normal;
}

.slider-box {
	margin-top: -120px;
}

.slider_preview_post {
	padding: 35px 0px 50px 0px;
}






.slider-box .all_bid_box {
	box-shadow: 3px 0px 11px rgba(0, 0, 0, 0.15);
	border-radius: 10px;
	margin-top: 20px;
	padding-bottom: 0px;
	background: #fff;
}

.all_bid_box .heading h1 {
	font-size: 20px;
	font-weight: 500;
}

.all_bid_box .heading h1 span {
	font-size: 18px;
	font-weight: 500;
	float: right;
	padding: 3px 0px 0px 0px;
	color: #79a1f6;
}

.all_bid_box .heading h1 span a {
	font-size: 18px;
	font-weight: 500;
	color: #79a1f6;
}

.all_bid_box .heading {
	padding: 1px 0px 0px 10px;
	border-bottom: 1px solid #dfe0e2;
}

.all_bid_box .all_bid_content {
	padding: 0px 10px;
}

.all_bid_content ul li {
	padding: 12px 0px;
	border-bottom: 1px solid #dfe0e2;
}

.all_bid_content ul li .content-box {
	position: relative;
	padding: 0px 0px 0px 77px;
}

.all_bid_content ul li .content-box h1 {
	font-size: 16px;
	margin: 0px;
}

.all_bid_content ul li .content-box h1 span {
	font-size: 14px;
	font-weight: 400;
	color: #8e8d8d;
	float: right;
}

.all_bid_content ul li .content-box p {
	font-size: 16px;
	color: #2c2c2c;
	margin: 6px 0px;
}

.all_bid_content ul li .content-box p span {
	font-size: 25px;
	font-weight: 600;
	float: right;
	color: #79a1f6;
}

.all_bid_content ul li .content-box p a {
	font-size: 16px;
	color: #2c2c2c;
	text-decoration: none;
}

.all_bid_content ul li .content-box p i {
	margin: 0px 2px 0px 1px;
	color: #f0c104;
}

.all_bid_content ul li:last-child {
	border-bottom: none;
}

.all_bid_box ul li .content-box img {
	width: 50px;
	height: 50px;
	border-radius: 100%;
	position: absolute;
	left: 0px;
	top: 0px;
}

.all_bid_box ul li .content-box a {
	font-size: 16px;
	color: #d66e52;
	text-decoration: underline;
	display: inline-block;
}

.slider_right_content h2.posted {
	font-size: 16px;
	margin: 0px 0px 14px 0px;
}

.slider_right_content h2.posted span {
	color: #8e8d8d;
}

/*active job_details css here*/

/*view bid message css here*/

.view_bid_section {
	padding: 20px 0px 40px 0px;
}

.all_bids_heading h1 {
	font-size: 32px;
	font-weight: 600;
	color: #000;
	margin-bottom: 14px;
}

.profile_info_box {
	max-width: 344px;
	position: absolute;
	left: 0px;
	width: 100%;
	height: 100%;
	overflow: auto;
}


.profile-info-List .content {
	position: relative;
	padding: 20px 0px 20px 96px;
}

.profile-info-List .content img {
	position: absolute;
	left: 0px;
	width: 73px;
	height: 73px;
	border-radius: 100%;
	top: 0px;
}

.view_bid_main_box {
	box-shadow: 2px 0px 8px rgba(0, 0, 0, 0.10);
	border-radius: 4px 0px 0px 0px;
	position: relative;
	padding: 0px 0px 0px 344px;
}

.profile-info-List .content h4 {
	font-size: 18px;
	font-weight: 500;
	margin-bottom: 6px;
}

.profile-info-List .content h4 span {
	font-size: 14px;
	font-weight: 400;
	color: #a9a9a9;
	float: right;
}

.span_btn_style span {
	background: #d66e52;
	font-size: 14px;
	color: #fff;
	font-weight: 500;
	border-radius: 60px;
	padding: 4px 14px;
	margin: 0px 6px 0px 0px;
}

.profile-info-List li {
	padding: 20px 15px;
	border-bottom: 1px solid #e5e5e5;
	background: #fff;
	cursor: pointer;
}

.profile-info-List li:last-child {
	border-bottom: none;
}

.profile-info-List li a {
	text-decoration: none;
}

.profile-info-List li.active {
	background: #f1f5fe;
}

.view_bid_main_box .heading h1 {
	font-size: 24px;
	font-weight: 400;
	color: #fff;
	background: #79a1f6;
	padding: 20px;
}

.view_bid_main_box .bid_message_content {
	padding: 0px 20px;
}

.bid_message_content ul li .left_content {
	float: left;
	position: relative;
	padding: 24px 0px 24px 146px;
}

.bid_message_content ul li .right_content {
	float: right;
	padding: 50px 0px 0px 0px;
}

.bid_message_content ul li .left_content img {
	width: 130px;
	height: 130px;
	border-radius: 100%;
	position: absolute;
	left: 0;
	top: 0;
}

.bid_message_content ul li .left_content h2 {
	font-size: 22px;
	font-weight: 500;
	color: #000;
}

.bid_message_content ul li .left_content p {
	font-size: 16px;
	color: #2c2c2c;
	margin: 4px 0px 6px 0px;
}

.bid_message_content ul li .left_content p a {
	color: #2c2c2c;
	display: inline-block;

}

.bid_message_content ul li .left_content p i {
	color: #f0c104;
	margin: 0px 2px 0px 0px;
}

.bid_message_content ul li {
	padding: 25px 0px;
	border-bottom: 1px solid #e5e5e5;
}

.bid_message_content .button_box a {
	font-size: 15px;
	background: #79a1f6;
	border-radius: 60px;
	padding: 8px 30px;
	color: #fff;
	display: inline-block;
	text-align: center;
	text-decoration: none;
	border: 1px solid transparent;
	box-shadow: 10px 0px 15px rgba(0, 127, 252, 0.15);
}

.bid_message_content .button_box a:hover {
	text-decoration: none;
	background: #5c90ff;
	color: #fff;
}

.bid_message_content .button_box a.decline {
	color: #d66e52;
	background: #fff;
	border: 1px solid #d66e52;
	margin: 0px 6px;
	box-shadow: 0px 10px 15px rgba(255, 147, 38, 0.15);
}

.bid_message_content .button_box a.chat {
	background: #d66e52;
	border: 1px solid transparent;
	box-shadow: 10px 0px 15px rgba(255, 147, 38, 0.15);
}

.bid_message_content .button_box a.chat:hover {
	background: #db565a;
	text-decoration: none;
}

.bid_message_content .button_box a.decline:hover {
	background: #db565a;
	text-decoration: none;
	color: #fff;
}

.bid_message_content ul li .message h4 {
	font-size: 18px;
	color: #aaaaaa;
	font-weight: 400;
}

.bid_message_content ul li .message p {
	font-size: 18px;
	margin-top: 10px;
}

.bid_message_content ul li .mileston h4 {
	margin: 0px 0px 17px 0px;
}

.bid_message_content ul li .mileston .mileston_list {
	position: relative;
	padding: 0px 0px 0px 50px;
	margin-bottom: 15px;
}

.bid_message_content ul li .mileston span {
	color: #79a1f6;
	float: right;
	font-size: 18px;
	font-weight: 600;
}

.bid_message_content ul li .mileston .list_span {
	display: inline-block;
	width: 33px;
	height: 33px;
	border-radius: 100%;
	border: 2px solid #dbdbdb;
	font-size: 18px;
	font-weight: 500;
	text-align: center;
	padding-top: 3px;
	position: absolute;
	left: 0px;
	color: #2c2c2c;
}

.bid_message_content ul li .mileston p {
	margin: 0px 0px 5px 0px;
	font-size: 18px;
	font-weight: 500;
}

.bid_message_content_box {
	border-left: 1px solid #e5e5e5;
}

.bid_message_content ul li:last-child {
	border: none;
}




.all_bid_content ul li .content-box p span.job-posted-10 {
	font-weight: bold;
	float: inherit;

}

.all_bid_content ul li .content-box h1 span.job-posted {
	font-size: 16px;
	font-weight: bold;
	color: #2c2c2c;
	float: inherit;
}

.all_bid_content ul li .job-detial-content-box {
	padding-left: 0px;
}

.job-detial-content-box .left-side-content {
	float: left;
	width: 30%;
	padding-top: 12px;
}

.job-detial-content-box .job-detils-left-box {
	width: 100%;
	padding: 13px 0 21px 62px;
}

.job-details-button-box {
	padding: 20px 20px 0px 20px;
}

.slider-box .carousel .slide {
	height: 300px;
	border-radius: 10px;
}

.slider-box .carousel .slide img {
	width: 100%;
	vertical-align: top;
	border: 0;
	height: 100%;
}

.slider-box .carousel .thumbs-wrapper {
	display: none;
}

.without_image {
	margin-top: 0px;
}

.loaderrrr {
	position: absolute;
	transform: translate(-50%, -50%);
	top: 50%;
	left: 50%;
	height: 100%;
	width: 100%;
}

.loaderrrr img {
	height: 90px;
	position: absolute;
	transform: translate(-50%);
	top: 50%;
	left: 50%;
}

.loader-back {
	background-color: white;
}

.all_bid_box.no_hire {
	padding-bottom: 0px;
	text-align: center;
}

.all_bid_box.no_hire .heading {
	border: none;
}

.no_found {
	padding: 30px;
}

.search_right {
	margin-top: 20px;
}

.pagination {
	display: inline-block;
	padding-left: 0;
	margin: 20px 0;
	border-radius: 4px;
	margin-left: 30px;
}

.bank_detail h4 {
	margin: 0 0 15px 0;
}


.pagination_box {
	position: relative;
	padding-bottom: 70px;
}

span.react-bs-table-sizePerPage-dropdown.dropdown {
	display: none !important;
}

.react-bootstrap-table-pagination-list {
	position: absolute;
	left: 0;
	bottom: 0;
}

.react-bootstrap-table table thead tr th:nth-child(6) {
	width: 130px;
}

.customer-message {
	font-weight: bold;
}

.block-user-image img {
	width: 60px;
	min-width: 60px;
	height: 60px;
	min-height: 60px;
	border-radius: 50%;
	border: 1px solid #0000002b;
	margin-right: 10px;
	object-fit: cover;
}

.block-user-name-reported-date {
	display: flex;
	justify-content: space-between;
}

.block-user-content {
	width: 100%;
}

.block-user-name-reported-date h6 {
	color: #000;
	font-size: 17px;
	font-weight: 600;
	margin: 0;
}

.block-user-name-reported-date p {
	font-size: 14px;
	color: gray;
	font-weight: 500;
}

.block-user-name-reported-date {
	display: flex;
	justify-content: space-between;
	margin-bottom: 5px;
	align-items: center;
}

.block-user-left {
	display: flex;
	border-bottom: 1px solid #0000001c;
	padding: 15px 0;
}

.reson-area {
	overflow: auto;
	max-height: 120px;
	height: 100%;
}